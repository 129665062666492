import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'

export const url = 'https://get.teamviewer.com/gninformatica'

export const TeamViewer = () => (
  <Button variant="warning" href={url}>
    <FontAwesomeIcon icon="headset" /> Avvia assistenza remota
  </Button>
)

export default TeamViewer
