import React from 'react'
import Layout from '../components/layout'
import { TeamViewer } from '../components/teamviewer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CardColumns, Card, Button, Container } from 'react-bootstrap'
import { PageTitle } from '../components/pagetitle'
import SEO from '../components/seo'

const MySite = () => (
  <Layout>
    <SEO title="Contattaci"/>
    <main>
      <Container>
        <PageTitle>
          I nostri <span className="accent">contatti</span>
        </PageTitle>
        <CardColumns>
          <Card>
            <Card.Body>
              <Card.Title>Email</Card.Title>
              <Card.Text>info@gnsoftware.it</Card.Text>
              <Button variant="warning" href="mailto:info@gnsoftware.it">
                <FontAwesomeIcon icon="envelope" /> Scrivici una mail
              </Button>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Chiamaci</Card.Title>
              <Card.Text>+39-340-789-9355</Card.Text>
              <Button variant="warning" href="tel:+39-340-789-9355">
                <FontAwesomeIcon icon="phone" /> Avvia chiamata
              </Button>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Facebook</Card.Title>
              <Card.Text>Rimani aggiornato sulle novità con Facebook</Card.Text>
              <Button variant="warning" href="https://fb.me/gnsoftware/">
                <FontAwesomeIcon icon="thumbs-up" /> Like
              </Button>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>LinkedIn</Card.Title>
              <Card.Text>Collegati con la nostra pagina su LinkedIn</Card.Text>
              <Button
                variant="warning"
                href="https://www.linkedin.com/company/gn-informatica/"
              >
                <FontAwesomeIcon icon={['fab', 'linkedin']} /> Collegati
              </Button>
            </Card.Body>
          </Card>
        </CardColumns>
        <br />
        <h2>Hai bisogno di assistenza?</h2>
        <p>Avvia ora una sessione di TeamViewer</p>
        <TeamViewer />
      </Container>
    </main>
  </Layout>
)

export default MySite
